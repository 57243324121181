import React from "react";
import Chart from "chart.js/auto";
import "bootstrap-icons/font/bootstrap-icons.css";

import { getCarMetadataStruct } from "./data_struct";

const metadata = getCarMetadataStruct();

/* Chart config */
const CHART_PERIOD = 60; // in seconds; width of displayed time frame
const CHART_X_AXIS_STEP = 5;
let num_of_datasets = 1;

let chart_time = 0;
let chart = null;

const generateColor = () => {
  let randomColorString = "#";
  const arrayOfColorFunctions = "0123456789abcdef";
  for (let x = 0; x < 6; x++) {
    let index = Math.floor(Math.random() * 16);
    let value = arrayOfColorFunctions[index];
    
    randomColorString += value;
  }
  return randomColorString;
};

var buttonColors = [generateColor()];

window.chart_data = [
  {
    label: "None",
    data: new Array(CHART_PERIOD),
    data_buffer: null,
    category: "",
    borderColor: buttonColors[0],
    backgroundColor: buttonColors[0],
  },
];

const chart_config = {
  type: "line",
  data: {
    labels: generateX_Axis(),
    datasets: window.chart_data,
  },
  options: {
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: false,
    spanGaps: true,
    scales: {
      x: {
        ticks: {
          callback: function (value, index, values) {
            const label = this.getLabelForValue(value);

            if (chart_time > CHART_PERIOD) {
              if (index === values.length - 1) {
                /* Always show the biggest label (the rightmost) */
                return label;
              }

              if (value > values.length - 4) {
                /* If new label appears on x axis, then give some room for this. Don't display 3 previous label behind the new one */
                return "";
              }
            }

            if (label % CHART_X_AXIS_STEP === 0) return label;
          },
        },
        title: {
          display: true,
          text: "Time (s)",
          color: "white",
        },
      },
      y: {
        title: {
          display: true,
          text: "Value",
          color: "white",
        },
      },
    },
  },
};

/* Functions */
function generateX_Axis() {
  const labels = [];

  for (let i = 1; i <= CHART_PERIOD; i++) {
    labels.push(i);
  }

  return labels;
}

function dataIsOnChart(data_id) {
  for (let i = num_of_datasets; i > 0; i--) {
    if (getDatasetDataID(i) === data_id) return i;
  }

  return 0;
}

function setDatasetDataType(dataset_id, data_id) {
  /* data_id can also be "None" */
  localStorage.setItem("dataset" + dataset_id, data_id);
  updateChartMetadata(dataset_id, data_id);
}

function getDatasetDataID(dataset_id) {
  return localStorage.getItem("dataset" + dataset_id);
}

function getDatasetCategory(dataset_id) {
  const data_id = getDatasetDataID(dataset_id);

  if (metadata[data_id]) return metadata[data_id].category;

  return "";
}

function updateChartMetadata(dataset_id, data_id) {
  window.chart_data[dataset_id - 1].category = getDatasetCategory(dataset_id);
  window.chart_data[dataset_id - 1].label = formatDatasetLabel(data_id);
}

function getDatasetLabel(dataset_id) {
  return formatDatasetLabel(getDatasetDataID(dataset_id));
}

function formatDatasetLabel(data_id) {
  if (metadata[data_id]) {
    let unit = metadata[data_id].unit ? `(${metadata[data_id].unit})` : "";
    return `${metadata[data_id].name} ${unit}`;
  }

  return "None";
}

function shiftArrayLeft(array) {
  for (let i = 0; i < array.length - 1; i++) {
    array[i] = array[i + 1];
  }
}

function clearArray(array) {
  for (let i = 0; i < array.length; i++) array[i] = null;
}

function isPinned(data_id) {
  return parseInt(localStorage.getItem(data_id));
}

function getScrollbarWidth() {
  const scrolling_el = document.createElement("div");
  scrolling_el.className = "scrollbar_test";
  document.body.appendChild(scrolling_el);

  return scrolling_el.offsetWidth - scrolling_el.clientWidth;
}

/* React components */
class ChartUI extends React.PureComponent {
  componentDidMount() {
    chart = new Chart(document.getElementById("data_chart"), chart_config);

    this.chart_interval = setInterval(() => {
      /* Chart is incremented every second */
      this.updateChartData();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.chart_interval);
  }

  render() {
    return (
      <section>
        <DataSelector />
        <canvas id="data_chart" width="400" height="200"></canvas>
      </section>
    );
  }

  updateChartData() {
    if (chart_time < CHART_PERIOD) {
      window.chart_data.forEach((dataset_id) => {
        dataset_id.data[chart_time] = dataset_id.data_buffer;
      });

      chart_time++;
    } else {
      window.chart_data.forEach((dataset_id) => {
        shiftArrayLeft(dataset_id.data);
        dataset_id.data[dataset_id.data.length - 1] = dataset_id.data_buffer;
      });

      shiftArrayLeft(chart.data.labels);
      chart_time++;
      chart.data.labels[chart.data.labels.length - 1] = chart_time;
    }

    chart.update();
  }
}

class DataSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cur_dataset: null,
      data_selector_open: false,
    };
  }
  render() {
    return (
      <div>
        <ChartSelectors
          openSelector={(dataset_id) => this.openSelector(dataset_id)}
        />
        {this.state.data_selector_open && (
          <ChartSelectorModal
            dataset_id={this.state.cur_dataset}
            closeSelector={() => this.closeSelector()}
          />
        )}
      </div>
    );
  }

  openSelector(dataset_id) {
    this.setState({ cur_dataset: dataset_id, data_selector_open: true });
    document.querySelector("body").classList.add("disable_scrolling");
  }

  closeSelector() {
    this.setState({ data_selector_open: false });
    document.querySelector("body").classList.remove("disable_scrolling");
  }
}

function ChartSelectors(props) {
  return (
    <div className="chart_selectors">
      {window.chart_data.map((dataset, i) => {
        const color = generateColor();
        buttonColors.push(color);
        return (
          <div key={i} className="chart_selector_box">
            {dataset.category && (
              <span className="dataset_category">{dataset.category}</span>
            )}

            <button
              //className={`btn dataset${i + 1}`}
              style={{backgroundColor:buttonColors[i],
                padding: "10px",
                border: 0,
                borderRadius: "10px",
                color: "white",
                fontWeight: 300,
                cursor: "pointer",
                height: "44px",
              }}
              onClick={() => props.openSelector(i + 1)}
            >
              {String(dataset.label)}
              <i className="bi bi-pencil-square"></i>
            </button>
          </div>
        );
      })}
    </div>
  );
}

class ChartSelectorModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.closeModalWithKey = this.closeModalWithKey.bind(this); // Make sure that "this" is about the React component
  }

  componentDidMount() {
    document.addEventListener("keydown", this.closeModalWithKey, false);

    if (!getScrollbarWidth())
      document
        .getElementById("chart_modal_header")
        .classList.add("modal_header_round_right_corner");
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.closeModalWithKey, false);
  }

  render() {
    return (
      <div id="chart_modal_overlay">
        <section id="chart_modal">
          <div id="chart_modal_content">
            <div id="chart_modal_header">
              <button
                id="clear_chart_selection"
                className={`btn btn_yellow ${
                  this.chartIsEmpty() ? "deactivated" : ""
                }`}
                onClick={() => this.clearChart()}
              >
                Clear selection
                <i className="bi bi-x-circle-fill"></i>
              </button>
              <button
                className="btn btn_red close_modal"
                onClick={() => this.props.closeSelector()}
              >
                Close
              </button>
            </div>
            <div id="chart_options">{this.generateDataSelection()}</div>
          </div>
        </section>
      </div>
    );
  }

  clearChart() {
    if (!this.chartIsEmpty()) this.chooseDataType("none");
  }

  chartIsEmpty() {
    return (
      getDatasetDataID(this.props.dataset_id) === "none" ||
      getDatasetDataID(this.props.dataset_id) === null
    );
  }

  selectedOptionIcon(data_type) {
    if (dataIsOnChart(data_type) === this.props.dataset_id)
      return <i className="bi bi-check-circle-fill"></i>;
  }

  getOptionStyle(data_type) {
    const data_type_cur_dataset = dataIsOnChart(data_type);

    if (data_type_cur_dataset) {
      if (data_type_cur_dataset === this.props.dataset_id) {
        return "btn dataset_option selected_dataset";
      }

      return "btn dataset_option deactivated";
    }

    return "btn dataset_option";
  }

  generateDataSelectionCategories() {
    const categories = {
      Pinned: [],
      Battery: [],
      Inverter: [],
      Motors: [],
      Radiator: [],
      Brakes: []
    };

    let data_option;
    let index = 0;
    for (let data_type in metadata) {
      if (isPinned(data_type)) {
        categories.Pinned.push(
          <button
            key={index++}
            className={this.getOptionStyle(data_type)}
            onClick={() => this.chooseDataType(data_type)}
          >
            {`${metadata[data_type].name} (${metadata[data_type].category})`}
            {this.selectedOptionIcon(data_type)}
          </button>
        );

        // continue;
      }

      data_option = (
        <button
          key={index++}
          className={this.getOptionStyle(data_type)}
          onClick={() => this.chooseDataType(data_type)}
        >
          {metadata[data_type].name}
          {this.selectedOptionIcon(data_type)}
        </button>
      );

      switch (metadata[data_type].category) {
        case "Battery":
          categories.Battery.push(data_option);
          break;
        case "Inverter":
          categories.Inverter.push(data_option);
          break;
        case "Motors":
          categories.Motors.push(data_option);
          break;
        case "Radiator":
          categories.Radiator.push(data_option);
          break;
        case "Brakes":
          categories.Brakes.push(data_option);
          break;
        default:
          break;
      }
    }

    return categories;
  }

  generateDataSelection() {
    const categories = this.generateDataSelectionCategories();

    return Object.keys(categories).map((category_name, i) => {
      if (categories[category_name].length) {
        return (
          <div key={i} className="chart_option_category">
            <span className="chart_option_category_title">{category_name}</span>
            {categories[category_name]}
          </div>
        );
      }

      return "";
    });
  }

  chooseDataType(data_type) {
    let previous_category = window.chart_data[this.props.dataset_id - 1].category; 
    if (!dataIsOnChart(data_type) || data_type === "none") {
      /* Clear old data */
      const dataset_obj = window.chart_data[this.props.dataset_id - 1];
      clearArray(dataset_obj.data);
      dataset_obj.data_buffer = null;
      chart.update();

      /* Set data info */
      setDatasetDataType(this.props.dataset_id, data_type);

      this.props.closeSelector();
    }

    // Push a new button to the datasets list
    if (((data_type !== "none") && ((this.props.dataset_id-1) <= num_of_datasets))) {
      if (previous_category === "") {
        const color = buttonColors[this.props.dataset_id];
        window.chart_data.push({
          label: getDatasetLabel(1),
          data: new Array(CHART_PERIOD),
          data_buffer: null,
          category: getDatasetCategory(1),
          borderColor: color,
          backgroundColor: color,
        })
        num_of_datasets += 1;
        setDatasetDataType(num_of_datasets, "none");
        chart.update();
      }
    } else {
      if ((this.props.dataset_id-1) <= num_of_datasets) {
        window.chart_data.splice(this.props.dataset_id, 1);
        num_of_datasets -= 1;
      }
    }
  }

  closeModalWithKey(event) {
    if (event.keyCode === 27) {
      /* ESC was pressed */
      this.props.closeSelector();
    }
  }
}

export { ChartUI, dataIsOnChart };
