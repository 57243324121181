import React from "react";

const MAX_BAT_ERROR_TYPE = 6;

class ErrorView extends React.PureComponent {
  render() {
    return (
      <div className="error_view">
        {this.props.front_left > 0 && (
          <ErrorBar category="Front Left" description={this.props.front_left} />
        )}

        {this.props.front_right > 0 && (
          <ErrorBar
            category="Front Right"
            description={this.props.front_right}
          />
        )}

        {this.props.rear_left > 0 && (
          <ErrorBar category="Rear Left" description={this.props.rear_left} />
        )}

        {this.props.rear_right > 0 && (
          <ErrorBar category="Rear Right" description={this.props.rear_right} />
        )}

        {this.showBatteryError()}
      </div>
    );
  }

  showBatteryError() {
    if (this.props.battery_error_type <= MAX_BAT_ERROR_TYPE) {
      return (
        <ErrorBar
          category={this.translateBatteryErrorType(
            this.props.battery_error_type
          )}
          description={this.translateBatteryErrorDescription(
            this.props.battery_error_description
          )}
        />
      );
    }
  }

  translateBatteryErrorType(type) {
    switch (type) {
      case 0:
        return "AMS";
      case 1:
        return "AMS";
      case 2:
        return "AMS";
      case 3:
        return "AMS CAN";
      case 4:
        return "AMS SPI";
      case 5:
        return "AMS MCU";
      case 6:
        return "IMD";
      default:
        break;
    }
  }

  translateBatteryErrorDescription(description) {
    switch (description) {
      case 1:
        return "Low voltage";
      case 2:
        return "High voltage";
      case 3:
        return "Low temperature";
      case 4:
        return "High temperature";
      case 5:
        return "High peak discharge";
      case 7:
        return "High continuous discharge";
      case 8:
        return "High peak charge";
      case 9:
        return "High continuous charge";
      case 10:
        return "Max current";
      case 11:
        return "IVT failed";
      default:
        break;
    }
  }
}

class ErrorBar extends React.PureComponent {
  render() {
    return (
      <div className="error_bar">
        <i className="bi bi-exclamation-circle"></i>
        <span
          className={
            "error_category" +
            (!this.props.description ? " no_description" : "")
          }
        >
          {this.props.category}
        </span>

        {this.props.description && (
          <span className="error_description">{this.props.description}</span>
        )}
      </div>
    );
  }
}

export default ErrorView;
