import React from "react";

class OnlineIndicator extends React.PureComponent {
  render() {
    if (this.props.online) {
      return <div className="car_online">Online</div>;
    } else {
      return <div className="car_offline">Offline</div>;
    }
  }
}

class CellularIndicator extends React.PureComponent {
  render() {
    if (this.props.cellular_conn) {
      return <div className="car_online">Connected</div>;
    } else {
      return <div className="car_offline">Not connected</div>;
    }
  }
}

class ViewSelectSwitch extends React.PureComponent {
  render() {
    return (
      <div>
        <button
          className={
            "btn selector_opt " +
            (this.props.cur_view === 0 ? "active_opt" : "")
          }
          onClick={() => this.props.viewSwitcher(0)}
        >
          All
        </button>
        <button
          className={
            "btn selector_opt " +
            (this.props.cur_view === 1 ? "active_opt" : "")
          }
          onClick={() => this.props.viewSwitcher(1)}
        >
          Table
        </button>
        <button
          className={
            "btn selector_opt " +
            (this.props.cur_view === 2 ? "active_opt" : "")
          }
          onClick={() => this.props.viewSwitcher(2)}
        >
          Chart
        </button>
        <button
          className={
            "btn selector_opt " +
            (this.props.cur_view === 3 ? "active_opt" : "")
          }
          onClick={() => this.props.viewSwitcher(3)}
        >
          Laptime
        </button>
      </div>
    );
  }
}

class LastOnlineTime extends React.PureComponent {
  render() {
    const time_ms = this.props.time * 1000;

    return (
      <span className="last_online_time">
        Updated on {this.formatDate(time_ms)}
      </span>
    );
  }

  formatDate(unix_timestamp) {
    const date = new Date(unix_timestamp);

    const options = {
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    return date.toLocaleDateString("en-GB", options);
  }
}

class Navbar extends React.PureComponent {
  render() {
    return (
      this.props.show && (
        <div className="navbar_box section">
          <nav className="navbar">
            <ViewSelectSwitch
              viewSwitcher={(view_code) => this.props.viewSwitcher(view_code)}
              cur_view={this.props.cur_view}
            />
            <CellularIndicator cellular_conn={this.props.cellular_conn} />
            <OnlineIndicator online={this.props.online} />
          </nav>

          <LastOnlineTime time={this.props.last_online_time} />
        </div>
      )
    );
  }
}

export default Navbar;
