import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA3VM_O5tK5MCGA_0M0dtmgNHmrzym-D_Q",
  authDomain: "fs-api-b8e14.firebaseapp.com",
  projectId: "fs-api-b8e14",
  storageBucket: "fs-api-b8e14.appspot.com",
  messagingSenderId: "348501116091",
  appId: "1:348501116091:web:806836b87ee45027a0d59e",
  measurementId: "G-P3XQX88JJ8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function getCarData() {
  const VEHICLE_ID = "2179d81043efb68c";
  const car_document_ref = doc(db, "vehicles", VEHICLE_ID);
  const car_document = await getDoc(car_document_ref);
  return car_document.data();
}

export { getCarData };
