import React from "react";

import DataUI from "./data_ui";
import { LoaderAnimation, OldDataNotice } from "./data_loader_ui";
import Navbar from "./navbar";
import { AuthForm, SignOutBox, monitorAuthStatus } from "./auth";

import "normalize.css";
import "./index.css";

const MASTER_AUTH_EMAIL = "info@formulastudent.ee";

const CONNECTION_TIMEOUT = 30; // Seconds
const ONLINE_CHECK_INTERVAL = 100; // Milliseconds

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data_is_loaded: false,

      online: false,
      cellular_conn: false,
      signal_strength: 0,
      not_been_online: true,
      show_old_data: false,
      last_online_time: null,

      view: 0, // 0 = all data;  1 = table; 2 = chart; 3 = laptime

      signed_in: false,
      user_email: MASTER_AUTH_EMAIL,
    };

    this.view_pos = [0, 0, 0, 0]; // Last scroll position for every view

    monitorAuthStatus((status) => this.setAuthStatus(status));
  }

  componentDidMount() {
    this.online_check = setInterval(
      () => this.checkIfCarIsOnline(this.state.last_online_time),
      ONLINE_CHECK_INTERVAL
    );
  }

  componentWillUnmount() {
    clearInterval(this.online_check);
  }

  render() {
    if (!this.state.signed_in) {
      return (
        <React.Fragment>
          <SiteHeader />
          <AuthForm email={this.state.user_email} />
        </React.Fragment>
      );
    }

    const content_is_visible =
      this.state.data_is_loaded &&
      (this.state.online ||
        this.state.show_old_data ||
        !this.state.not_been_online);

    return (
      <React.Fragment>
        {this.showDataLoader()}
        <SiteHeader signed_in={this.state.signed_in} />
        <Navbar
          show={content_is_visible}
          online={this.state.online}
          cellular_conn={this.state.cellular_conn}
          signal_strength={this.state.signal_strength}
          cur_view={this.state.view}
          viewSwitcher={(view_code) => this.setView(view_code)}
          last_online_time={this.state.last_online_time}
        />

        <DataUI
          signed_in={this.state.signed_in}
          dataIsLoaded={() => this.dataIsLoaded()}
          show={content_is_visible}
          view={this.state.view}
          setLastOnlineTime={(time) => this.setLastOnlineTime(time)}
          setCellularState={(state) => this.setCellularState(state)}
          setSignalStrength={(strength) => this.setSignalStrength(strength)}
          cellular_conn={this.state.cellular_conn}
          signal_strength={this.state.signal_strength}
        />
      </React.Fragment>
    );
  }

  setAuthStatus(user) {
    if (user) {
      this.setState({ signed_in: true });
    } else {
      this.setState({ signed_in: false });
    }
  }

  setView(view_code) {
    /* Save current view scroll position so user can easily come back */
    const distance_from_top = Math.abs(
      Math.floor(document.body.getBoundingClientRect().top)
    );
    this.view_pos[this.state.view] = distance_from_top;

    /* Put scroll position where user was previously */
    setTimeout(() => {
      /* Timeout is used to give time to React for displaying content. If scroll happens before content changes, then scroll would be useless. */
      window.scrollTo({
        top: this.view_pos[view_code],
        left: 0,
      });
    }, 0.1);

    /* Change view */
    this.setState({ view: view_code });
  }

  showDataLoader() {
    return (
      <React.Fragment>
        <LoaderAnimation show={!this.state.data_is_loaded} />
        <OldDataNotice
          show={
            this.state.data_is_loaded &&
            this.state.not_been_online &&
            !this.state.show_old_data
          }
          showOldData={() => this.showOldData()}
        />
      </React.Fragment>
    );
  }

  showOldData() {
    this.setState({ show_old_data: true });
  }

  checkIfCarIsOnline(last_online_time) {
    if (!last_online_time) return;

    const cur_time = Date.now() / 1000; // Divided by 1000 to get seconds

    if (cur_time - last_online_time < CONNECTION_TIMEOUT)
      this.setState({ online: true, not_been_online: false });
    else this.setState({ online: false });
  }

  setLastOnlineTime(last_online_time) {
    this.setState({ last_online_time: last_online_time });
  }

  dataIsLoaded() {
    this.setState({ data_is_loaded: true });
    this.checkIfCarIsOnline(this.state.last_online_time);
  }
  setCellularState(state) {
    this.setState({ cellular_conn: state });
  }
  
  setSignalStrength(strength) {
    this.setState({ signal_strength: strength });
  }
}

class SiteHeader extends React.PureComponent {
  render() {
    return (
      <section className="section">
        {this.props.signed_in && <SignOutBox />}

        <span className="site_title">Data</span>
        <span className="site_subtitle">Telemetry dashboard</span>
      </section>
    );
  }
}

export default App;
