import React from "react";

class Pin extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  componentDidMount() {
    this.setState({ active: this.props.init_state });
  }

  render() {
    if (this.props.demo) {
      return (
        <button className={"btn pin"}>
          <i className="bi bi-pin-angle"></i>
        </button>
      );
    }

    return (
      <button
        className={
          "btn pin" +
          (this.state.active ? " pin_active" : "") +
          (this.props.no_corner ? "" : " pin_corner")
        }
        onClick={() => {
          this.togglePin();
        }}
      >
        <i className="bi bi-pin-angle"></i>
      </button>
    );
  }

  togglePin() {
    this.setState({ active: !this.state.active });
    this.props.pinAction(this.props.toggle_name);
  }
}

// class Toggle extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     this.state = {
//       on: props.on,
//     };
//   }

//   render() {
//     return (
//       <div className="toggle_box">
//         <div className="toggle" onClick={() => this.toggleSwitch()}>
//           <span className={this.getSwitchState()}></span>
//         </div>
//         <div className="toggle_text">
//           <span className="toggle_label">{this.props.label}</span>
//           {this.props.desc && (
//             <React.Fragment>
//               <div className="line_break_flex"></div>
//               <span className="toggle_desc">{this.props.desc}</span>
//             </React.Fragment>
//           )}
//         </div>
//       </div>
//     );
//   }

//   getSwitchState() {
//     if (this.state.on) {
//       return "toggle_knob toggle_on";
//     } else {
//       return "toggle_knob toggle_off";
//     }
//   }

//   toggleSwitch() {
//     this.setState({ on: this.props.toggle() });
//   }
// }

export { Pin };
