import React, { useState, useRef } from "react";
import Chart from "chart.js/auto";
import "bootstrap-icons/font/bootstrap-icons.css";
import './LapTimerUI.css';
import { getCarDataStruct } from "./data_struct";

/* React components */
class LaptimeUI extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
          numberOfLaps: 0,
          laps: [],
          isRunning: false,
          currentLapTime: 0,
          car_data: props.carstate || 0,
          maxConsumption: 6600,
          maxAccumulatorTemp: 60,
          previousConsumption: 0,
        };
    
        this.startTimeRef = null;
        this.lapTimeRef = null;
        this.intervalId = null;
        this.chartRef = React.createRef();
        this.accTempChartRef = React.createRef();
        this.efficiencyChartRef = React.createRef();
        this.chartInstance = null;
        this.accTempChartInstance = null;
        this.efficiencyChartInstance = null;
      }
    
      handleStart = () => {
        this.setState({
          laps: Array(this.state.numberOfLaps).fill({ lapTime: '', consumption: '', efficiency: '' }),
          isRunning: true,
          currentLapTime: 0,
          previousConsumption: 0
        });
        this.startTimeRef = Date.now();
        this.lapTimeRef = this.startTimeRef;
    
        this.intervalId = setInterval(() => {
          const now = Date.now();
          this.setState({
            currentLapTime: (now - this.lapTimeRef) / 1000,
          });
        }, 100);
      };

      getBestFieldStyle = (field, value) => {
        const { laps } = this.state;
    
        let bestValue;
        switch (field) {
          case 'lapTime':
            bestValue = Math.min(...laps.map(lap => parseFloat(lap.lapTime) || Infinity));
            break;
          case 'consumption':
            bestValue = Math.min(...laps.map(lap => parseFloat(lap.consumption) || Infinity));
            break;
          case 'efficiency':
            bestValue = Math.max(...laps.map(lap => parseFloat(lap.efficiency) || -Infinity));
            break;
          default:
            return {};
        }
    
        return {
          backgroundColor: parseFloat(value) === bestValue ? 'green' : '',
        };
      };

      handleMaxConsumptionChange = (event) => {
        this.setState({ maxConsumption: parseFloat(event.target.value) || 0 });
      };
    
      handleLap = () => {
        if (!this.state.isRunning) return;
    
        const now = Date.now();
        const lapTime = (now - this.lapTimeRef) / 1000; // lap time in seconds
        
        this.lapTimeRef = now;

        console.log(this.props.carstate);
    
        this.setState((prevState) => {
            const newLaps = prevState.laps.slice();
            const lapIndex = prevState.laps.findIndex(lap => lap.lapTime === '');
    
            const consumption = (this.props.carstate.accIvtOverallEnergy - prevState.previousConsumption).toFixed(2);
            const efficiency = (1000000/(lapTime*lapTime*consumption)).toFixed(2);
            const accumulatorTemp = (this.props.carstate.accAmsMaxTemp).toFixed(2);
    
            if (lapIndex !== -1) {
                newLaps[lapIndex] = {
                    lapTime,
                    consumption,
                    efficiency,
                    accumulatorTemp, // Add accumulator temperature to lap data
                };
            }
    
            const shouldStop = newLaps.filter(lap => lap.lapTime !== '').length >= prevState.numberOfLaps;
            if (shouldStop) {
                clearInterval(this.intervalId);
            }
    
            return {
                laps: newLaps,
                currentLapTime: 0,
                isRunning: !shouldStop,
                previousConsumption: this.props.carstate.accIvtOverallEnergy,
            };
        }, this.updateCharts);
    };
    
      handleChange = (e) => {
        const newLapCount = parseInt(e.target.value, 10);
    
        if (isNaN(newLapCount) || newLapCount <= 0) return; // Ensure newLapCount is a valid positive number
    
        this.setState((prevState) => {
          const newLaps = prevState.laps.slice(0, newLapCount).concat(
            Array(Math.max(0, newLapCount - prevState.laps.length)).fill({ lapTime: '', consumption: '', efficiency: '' })
          );
    
          const updatedLaps = newLaps.slice(0, newLapCount);
    
          return {
            numberOfLaps: newLapCount,
            laps: updatedLaps,
          };
        }, this.updateCharts);
      };

      calculateTrendline = (data) => {
        // Filter out invalid values and retain indices
        const validData = data
            .map((value, index) => ({ value, index }))
            .filter(item => !isNaN(item.value) && item.value !== 0);
    
        if (validData.length < 3) {
            return new Array(data.length).fill(null); // Not enough data points to calculate trendline
        }
    
        // Use the last three valid data points
        const lastThreeData = validData.slice(-3);
    
        const n = lastThreeData.length;
        const sumX = lastThreeData.reduce((sum, item) => sum + item.index, 0);
        const sumY = lastThreeData.reduce((sum, item) => sum + item.value, 0);
        const sumXY = lastThreeData.reduce((sum, item) => sum + item.index * item.value, 0);
        const sumX2 = lastThreeData.reduce((sum, item) => sum + item.index * item.index, 0);
    
        const denominator = (n * sumX2 - sumX * sumX);
        if (denominator === 0) {
            // This handles the case where there's no variation in x values (e.g., all indices are the same)
            return new Array(data.length).fill(sumY / n);
        }
    
        const slope = (n * sumXY - sumX * sumY) / denominator;
        const intercept = (sumY - slope * sumX) / n;
    
        return data.map((_, x) => slope * x + intercept);
    };
    
    
    
    

    getAccTempData = () => {
        const { numberOfLaps, laps, maxAccumulatorTemp } = this.state;
    
        // Labels for each lap
        const labels = Array.from({ length: numberOfLaps }, (_, index) => `Lap ${index + 1}`);
    
        // Accumulator temperature data
        const accTempData = laps.map(lap => parseFloat(lap.accumulatorTemp) || 0);
    
        // Max accumulator temperature line
        const maxTempLine = new Array(numberOfLaps).fill(maxAccumulatorTemp);
    
        // Calculate trendline data based on the last three measured values
        const trendlineData = this.calculateTrendline(accTempData);
    
        return {
            labels,
            datasets: [
                {
                    label: 'Accumulator Temperature',
                    data: accTempData,
                    fill: false,
                    borderColor: 'rgba(153, 102, 255, 1)',
                },
                {
                    label: 'Temperature Trend',
                    data: trendlineData,
                    fill: false,
                    borderColor: 'rgba(75,192,192,1)',
                    tension: 0.1,
                },
                {
                    label: 'Max Accumulator Temp',
                    data: maxTempLine,
                    fill: false,
                    borderColor: 'rgba(255,99,132,1)',
                    borderDash: [5, 5],
                },
            ],
        };
    };
    

      getTrendlineData = () => {
        const measuredLaps = this.state.laps.filter(lap => lap.lapTime !== '');
        const totalLaps = this.state.numberOfLaps;
      
        // Create labels for all laps
        const labels = Array.from({ length: totalLaps }, (_, index) => `Lap ${index + 1}`);
      
        // Create cumulative consumption data for all laps
        let cumulativeData = [];
        let cumulativeSum = 0;
        this.state.laps.forEach(lap => {
          const consumption = parseFloat(lap.consumption) || 0;
          cumulativeSum += consumption;
          cumulativeData.push(cumulativeSum);
        });
      
        // Initialize trendline data
        let trendlineData = new Array(totalLaps).fill(null);
      
        if (measuredLaps.length >= 3) {
          // Take the last three measured laps
          const lastThreeLaps = measuredLaps.slice(-3);
      
          // Determine indices for the last three laps
          const indices = lastThreeLaps.map(lap => this.state.laps.indexOf(lap));
      
          const [index1, index2, index3] = indices;
          const [y1, y2, y3] = lastThreeLaps.map(lap => cumulativeData[indices.shift()]);
      
          // Calculate slope and intercept for the trendline
          const xMean = (index1 + index2 + index3) / 3;
          const yMean = (y1 + y2 + y3) / 3;
      
          const numerator = ((index1 - xMean) * (y1 - yMean)) +
                            ((index2 - xMean) * (y2 - yMean)) +
                            ((index3 - xMean) * (y3 - yMean));
          const denominator = ((index1 - xMean) ** 2) +
                              ((index2 - xMean) ** 2) +
                              ((index3 - xMean) ** 2);
          const slope = numerator / denominator;
          const intercept = yMean - (slope * xMean);
      
          // Generate trendline data based on cumulative values
          trendlineData = trendlineData.map((_, index) => slope * index + intercept);
        }
      
        // Use the user-defined max consumption value
        const maxConsumption = this.state.maxConsumption;
      
        return {
          labels,
          datasets: [
            {
              label: 'Measured Consumption',
              data: cumulativeData,
              fill: false,
              borderColor: 'rgba(75,99,132,1)',
            },
            {
              label: 'Consumption Trend',
              data: trendlineData,
              fill: false,
              borderColor: 'rgba(75,192,192,1)',
              tension: 0.1,
            },
            {
              label: 'Max Consumption',
              data: new Array(totalLaps).fill(maxConsumption),
              fill: false,
              borderColor: 'rgba(255,99,132,1)',
              borderDash: [5, 5],
            },
          ],
        };
      };
      
 
      getEfficiencyData = () => {
        const labels = this.state.laps.map((_, index) => `Lap ${index + 1}`);
        const efficiencyData = this.state.laps.map(lap => parseFloat(lap.efficiency) || 0);

        return {
            labels,
            datasets: [
                {
                    label: 'Efficiency',
                    data: efficiencyData,
                    fill: false,
                    borderColor: 'rgba(153,102,255,1)',
                    tension: 0.1,
                },
            ],
        };
    };
    
    updateCharts = () => {
        if (this.chartInstance) {
            this.chartInstance.data = this.getTrendlineData();
            this.chartInstance.update();
        }
        if (this.accTempChartInstance) {
            this.accTempChartInstance.data = this.getAccTempData();
            this.accTempChartInstance.update();
        }
        if (this.efficiencyChartInstance) {
            this.efficiencyChartInstance.data = this.getEfficiencyData();
            this.efficiencyChartInstance.update();
        }
    };

    componentDidMount() {
        const ctx = this.chartRef.current.getContext('2d');
        this.chartInstance = new Chart(ctx, {
            type: 'line',
            data: this.getTrendlineData(),
            options: {
                responsive: true,
                scales: {
                    x: {
                        beginAtZero: true,
                    },
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });

        const accTempCtx = this.accTempChartRef.current.getContext('2d');
        this.accTempChartInstance = new Chart(accTempCtx, {
            type: 'line',
            data: this.getAccTempData(),
            options: {
                responsive: true,
                scales: {
                    x: {
                        beginAtZero: true,
                    },
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });

        const efficiencyCtx = this.efficiencyChartRef.current.getContext('2d');
        this.efficiencyChartInstance = new Chart(efficiencyCtx, {
            type: 'line',
            data: this.getEfficiencyData(),
            options: {
                responsive: true,
                scales: {
                    x: {
                        beginAtZero: true,
                    },
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
    }
    
    componentDidUpdate(prevProps, prevState) {
        if (prevState.laps !== this.state.laps || prevState.numberOfLaps !== this.state.numberOfLaps) {
            this.updateCharts();
        }
    }
    
    componentWillUnmount() {
        if (this.chartInstance) {
            this.chartInstance.destroy();
        }
        if (this.accTempChartInstance) {
            this.accTempChartInstance.destroy();
        }
        if (this.efficiencyChartInstance) {
            this.efficiencyChartInstance.destroy();
        }
        clearInterval(this.intervalId);
    }
    
    render() {
        return (
            <div className="lap-timer-ui">
                <div className="controls">
                    <label>
                        Number of Laps:
                        <input
                            type="number"
                            value={this.state.numberOfLaps}
                            onChange={this.handleChange}
                            className="input-field"
                        />
                    </label>
                    <button onClick={this.handleStart} className="button start-button">Start</button>
                    <button
                        onClick={this.handleLap}
                        disabled={!this.state.isRunning || this.state.laps.filter(lap => lap.lapTime !== '').length >= this.state.numberOfLaps}
                        className="button lap-button"
                    >
                        Lap
                    </button>
                </div>
                <div className="lap-and-chart-container">
                    <div className="lap-times">
                        {this.state.isRunning && (
                            <div className="current-lap-time">
                                <h4>Current Lap Time: {this.state.currentLapTime.toFixed(2)} seconds</h4>
                            </div>
                        )}
                        <table className="lap-table">
                            <thead>
                                <tr>
                                    <th>Lap</th>
                                    <th>Lap Time (s)</th>
                                    <th>Consumption</th>
                                    <th>Efficiency (LT^2*C)</th>
                                    <th>Accumulator Temp (°C)</th> {/* New column header */}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.laps.map((lap, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td style={this.getBestFieldStyle('lapTime', lap.lapTime)}>{lap.lapTime !== '' ? lap.lapTime.toFixed(2) : ''}</td>
                                        <td style={this.getBestFieldStyle('consumption', lap.consumption)}>{lap.consumption}</td>
                                        <td style={this.getBestFieldStyle('efficiency', lap.efficiency)}>{lap.efficiency}</td>
                                        <td>{lap.accumulatorTemp}</td> {/* Display accumulator temperature */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="chart-container">
                        <label>
                            Max Consumption:
                            <input
                                type="number"
                                value={this.state.maxConsumption}
                                onChange={this.handleMaxConsumptionChange}
                                className="input-field"
                            />
                        </label>
                        <canvas ref={this.chartRef} />
                        <canvas ref={this.accTempChartRef} />
                        <canvas ref={this.efficiencyChartRef} />
                    </div>
                </div>
            </div>
        );
    }
}

export { LaptimeUI };
