import React from "react";
import "bootstrap-icons/font/bootstrap-icons.css";

import { getCarData } from "./data";
import { getCarDataStruct, getCarMetadataStruct } from "./data_struct";
import { DataTable, DataRow, Category, DataBox } from "./data_view";
import ErrorView from "./error_view";
import { ChartUI, dataIsOnChart } from "./chart_ui";
import { LaptimeUI } from "./laptime_ui";

const NEW_DATA_INTERVAL = 1000; // Unit: ms

const FAN_VARIABLES = ["cooDashPumpMode", "cooDashRadFan", "cooDashBatFan"];
const FRONT_SUPPORTS = ["cooFlSupportTemp", "cooFrSupportTemp"]
const ACC_VOLTAGES = ["accAmsMinVoltage",  "accAmsAvgVoltage",  "accAmsMaxVoltage"]
const FAN_MODES = ["Off", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Max", "Auto"];

class DataUI extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = getCarDataStruct();
    this.metadata = getCarMetadataStruct();
  }

  componentDidMount() {
    this.data_update = setInterval(() => {
      this.updateData();
    }, NEW_DATA_INTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.data_update);
  }

  render() {
    return (
      this.props.show && (
        <section>
          <ErrorView
            battery_error_type={this.state.accAmsImdErrorType}
            battery_error_description={
              this.state.accAmsImdErrorDescription
            }
            front_left={this.state.amkFlErrorInfo}
            front_right={this.state.amkFrErrorInfo}
            rear_left={this.state.amkRlErrorInfo}
            rear_right={this.state.amkRrErrorInfo}
          />
          {this.props.view === 0 && <div>{this.showAllData()}</div>}
          {this.props.view === 1 && <div>{this.showPinnedData()}</div>}
          {this.props.view === 2 && <ChartUI />}
          {this.props.view === 3 && <LaptimeUI carstate = {this.state}/>}
        </section>
      )
    );
  }

  async updateData() {
    const new_data = await getCarData();
    
    for (let parameter in new_data) {
      var new_value = Math.floor(new_data[parameter] * 100) / 100;
      //Next if else.. is a hack
      if(FRONT_SUPPORTS.includes(parameter)){
        new_value = (parseInt((new_data[parameter] * 10), 10) & 0x7FFF) / 10
      }
      else if(ACC_VOLTAGES.includes(parameter)){
        new_value = (parseInt((new_data[parameter] * 10), 10)) / 10;
      }

      if (this.metadata[parameter]) {
        if (new_value !== this.state[parameter])
          this.setState({ [parameter]: new_value });

        /* Update chart data */
        let dataset_number = dataIsOnChart(parameter);

        if (dataset_number !== 0)
          window.chart_data[dataset_number - 1].data_buffer = new_value;
      }
    }

    this.props.setLastOnlineTime(this.state.lastUpdate);
    this.props.setCellularState(this.state.cellularConnection);
    this.props.setSignalStrength(this.state.gsmSignalStrength);
    this.props.dataIsLoaded();
  }

  addDataBoxToCategory() {
    const categories = {
      Battery: [],
      Inverter: [],
      Motors: [],
      Radiator: [],
      Brakes: [],
    };

    let data_box;
    let index = 0;

    for (let parameter in this.state) {
      var value = 0;
      if(FAN_VARIABLES.includes(parameter)){
        value = FAN_MODES[this.state[parameter]];
      }
      else if(FRONT_SUPPORTS.includes(parameter)){
        value = (parseInt((this.state[parameter] * 10), 10) & 0x7FFF) / 10
      }
      else if(ACC_VOLTAGES.includes(parameter)){
        value = (parseInt((this.state[parameter] * 10), 10)) / 100;
      }
      else{
        value = this.state[parameter];
      }
      data_box = (
        <DataBox
          key={index++}
          id={parameter}
          // Next line is janky hack, could be done better.
          //val={(FAN_VARIABLES.includes(parameter)) ? FAN_MODES[this.state[parameter]] : FRONT_SUPPORTS.includes(parameter) ? (parseInt((this.state[parameter] * 10), 10) & 0x7FFF) / 10 : this.state[parameter]}
          val = {value}
          name={this.metadata[parameter].name}
          unit={this.metadata[parameter].unit}
          pinAction={(data_id) => this.togglePinned(data_id)}
          is_pinned={this.isPinned(parameter)}
        />
      );

      switch (this.metadata[parameter].category) {
        case "Battery":
          categories.Battery.push(data_box);
          break;
        case "Inverter":
          categories.Inverter.push(data_box);
          break;
        case "Motors":
          categories.Motors.push(data_box);
          break;
        case "Radiator":
          categories.Radiator.push(data_box);
          break;
        case "Brakes":
          categories.Brakes.push(data_box);
          break;
        default:
          break;
      }
    }

    return categories;
  }

  createPinnedDataRows() {
    return Object.keys(this.state).map((parameter, i) => {
      if (this.isPinned(parameter))
        return (
          <DataRow
            key={i}
            id={parameter}
            val={this.state[parameter]}
            category={this.metadata[parameter].category}
            name={this.metadata[parameter].name}
            unit={this.metadata[parameter].unit}
            pinAction={(data_id) => this.togglePinned(data_id)}
            is_pinned={true}
          />
        );

      return null;
    });
  }

  showPinnedData() {
    return <DataTable data={this.createPinnedDataRows()} />;
  }

  showAllData() {
    const categories = this.addDataBoxToCategory();

    const html = [];
    let index = 0;
    for (let key in categories) {
      html.push(
        <Category key={index++} name={key}>
          {categories[key]}
        </Category>
      );
    }

    return html;
  }

  isPinned(parameter_id) {
    return parseInt(localStorage.getItem(parameter_id));
  }

  togglePinned(parameter_id) {
    if (this.isPinned(parameter_id)) {
      localStorage.setItem(parameter_id, 0);
    } else {
      localStorage.setItem(parameter_id, 1);
    }

    this.forceUpdate();
  }
}

export default DataUI;
