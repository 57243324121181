import React from "react";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

/* Error messages */
const ERR_MSG_WRONG_PWD = "Wrong password";
const ERR_MSG_NO_PWD = "Password missing";

/* Firebase */
const auth_data = getAuth();

/* Don't show sign in form before it's determined whether user is signed in or not. Is user is signed in, then sign in form would be shown for a moment and then disappears. It would look ugly. */
window.auth_status_known = false;

function monitorAuthStatus(callback = () => {}) {
  onAuthStateChanged(auth_data, (user) => {
    window.auth_status_known = true;
    callback(user);
  });
}

/* React */
class AuthForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      err_msg: "",
    };
  }

  render() {
    return (
      window.auth_status_known && (
        <form id="auth_form" onSubmit={(e) => this.signIn(e)}>
          <label htmlFor="input_pwd" id="txt_input_pwd">
            Password
          </label>
          <div id="auth_form_input_line">
            <input
              type="password"
              id="input_pwd"
              name="input_pwd"
              placeholder="Type password"
              onChange={(e) => this.setPassword(e)}
            ></input>
            <button
              id="btn_auth"
              className="btn btn_round"
              onClick={(e) => this.signIn(e)}
            >
              <i className="bi bi-unlock-fill"></i>
            </button>
          </div>

          {this.state.err_msg && (
            <span id="auth_error">{this.state.err_msg}</span>
          )}
        </form>
      )
    );
  }

  setPassword(event) {
    this.setState({ password: event.target.value });
  }

  signIn(event) {
    event.preventDefault();

    if (!this.state.password) {
      this.setState({ err_msg: ERR_MSG_NO_PWD });
      return;
    }

    signInWithEmailAndPassword(
      auth_data,
      this.props.email,
      this.state.password
    ).catch((err) => this.setErrMsg(ERR_MSG_WRONG_PWD));
  }

  setErrMsg(msg) {
    this.setState({ err_msg: msg });
  }
}

class SignOutBox extends React.PureComponent {
  render() {
    return (
      <div id="logout_box">
        <button className="btn btn_red" onClick={() => this.signOutEvent()}>
          Sign Out
        </button>
      </div>
    );
  }

  signOutEvent() {
    signOut(auth_data).catch((err) => console.log(err));
  }
}

export { AuthForm, SignOutBox, monitorAuthStatus };
