const metadata = {
  lastUpdate: {},
  accAmsAvgTemp: { category: "Battery", name: "Average Temp", unit: "°C" },
  accAmsAvgVoltage: { category: "Battery", name: "Average Voltage", unit: "V" },
  accIvtOverallEnergy: { category: "Battery", name: "Energy", unit: "Wh" },
  accAmsImdErrorDescription: { category: "error", sub: "Battery", name: "Description" },
  accAmsImdErrorType: { category: "error", sub: "Battery", name: "Component", unit: "" },
  cooDashBatFan: { category: "Battery", name: "Fan Mode", unit: "" },
  accAmsMaxTemp: { category: "Battery", name: "Max Temp", unit: "°C" },
  accAmsMaxVoltage: { category: "Battery", name: "Max Voltage", unit: "V" },
  accAmsMinTemp: { category: "Battery", name: "Min Temp", unit: "°C" },
  accAmsMinVoltage: { category: "Battery", name: "Min Voltage", unit: "V" },
  accAmsStateOfCharge: { category: "Battery", name: "Charge", unit: "%" },
  amkFlErrorInfo: { category: "error", sub: "Motors", name: "Front Left", unit: "" },
  amkFrErrorInfo: { category: "error", sub: "Motors", name: "Front Right", unit: "" },
  amkRlErrorInfo: { category: "error", sub: "Motors", name: "Rear Left", unit: "" },
  amkRrErrorInfo: { category: "error", sub: "Motors", name: "Rear Right", unit: "" },
  amkFlTempInverter: { category: "Inverter", name: "Front Left", unit: "°C" },
  amkFrTempInverter: { category: "Inverter", name: "Front Right", unit: "°C" },
  amkRlTempInverter: { category: "Inverter", name: "Rear Left", unit: "°C" },
  amkRrTempInverter: { category: "Inverter", name: "Rear Right", unit: "°C" },
  cooDashPumpMode: { category: "Inverter", name: "Pump mode", unit: ""},
  fischerFlTempMotor: { category: "Motors", name: "Front Left", unit: "°C" },
  fischerFrTempMotor: { category: "Motors", name: "Front Right", unit: "°C" },
  fischerRlTempMotor: { category: "Motors", name: "Rear Left", unit: "°C" },
  fischerRrTempMotor: { category: "Motors", name: "Rear Right", unit: "°C" },
  cooDashRadFan: { category: "Radiator", name: "Fan Mode", unit: "" },
  cooLeftRadiatorInlet: { category: "Radiator", name: "Left In", unit: "°C" },
  cooRightRadiatorInlet: { category: "Radiator", name: "Right In", unit: "°C" },
  cooLeftRadiatorOutlet: { category: "Radiator", name: "Left Out", unit: "°C" },
  cooRightRadiatorOutlet: { category: "Radiator", name: "Right Out", unit: "°C" },
  cooFlSupportTemp: { category: "Brakes", name: "Front Left", unit: "°C" },
  cooFrSupportTemp: { category: "Brakes", name: "Front Right", unit: "°C" },
  cooSupportRl: { category: "Brakes", name: "Rear Left", unit: "°C" },
  cooSupportRr: { category: "Brakes", name: "Rear Right", unit: "°C" },
  cellularConnection: {},
  gsmSignalStrength: {}
};

const data = {
  lastUpdate: null,
  accAmsAvgTemp: null,
  accAmsAvgVoltage: null,
  accIvtOverallEnergy: null,
  accAmsImdErrorDescription: null,
  accAmsImdErrorType: null,
  cooDashBatFan: null,
  accAmsMaxTemp: null,
  accAmsMaxVoltage: null,
  accAmsMinTemp: null,
  accAmsMinVoltage: null,
  accAmsStateOfCharge: null,
  amkFlErrorInfo: null,
  amkFrErrorInfo: null,
  amkRlErrorInfo: null,
  amkRrErrorInfo: null,
  amkFlTempInverter: null,
  amkFrTempInverter: null,
  amkRlTempInverter: null,
  amkRrTempInverter: null,
  cooDashPumpMode: null,
  fischerFlTempMotor: null,
  fischerFrTempMotor: null,
  fischerRlTempMotor: null,
  fischerRrTempMotor: null,
  cooDashRadFan: null,
  cooLeftRadiatorInlet: null,
  cooRightRadiatorInlet: null,
  cooLeftRadiatorOutlet: null,
  cooRightRadiatorOutlet: null,
  cooFlSupportTemp: null,
  cooFrSupportTemp: null,
  cooSupportRl: null,
  cooSupportRr: null,
  cellularConnection: null,
  gsmSignalStrength: null
};

function copyObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function getCarMetadataStruct() {
  return copyObject(metadata);
}

function getCarDataStruct() {
  return copyObject(data);
}

export { getCarDataStruct, getCarMetadataStruct };
