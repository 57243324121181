import React from "react";

import { Pin } from "./switches";

/* Table view */
class DataTable extends React.PureComponent {
  render() {
    return (
      <div>
        <table className="data_table">
          <thead>
            <tr className="data_header">
              <th className="data_header_item">Category</th>
              <th className="data_header_item">Parameter</th>
              <th className="data_header_item">Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{this.addRows()}</tbody>
        </table>
      </div>
    );
  }

  addRows() {
    let table_is_empty = true;

    for (let i in this.props.data) {
      if (this.props.data[i]) {
        table_is_empty = false;
        break;
      }
    }

    if (table_is_empty) {
      return (
        <tr key={0}>
          <td className="table_empty_notice">
            Use {<Pin demo={true} />} to add data.
          </td>
        </tr>
      );
    }

    return this.props.data;
  }
}

class DataRow extends React.PureComponent {
  render() {
    return (
      <tr className="data_row selectable">
        <td className="data_row_category">{this.props.category}</td>
        <td className="data_row_name">{this.props.name}</td>
        <td className="data_row_value">
          {this.props.val} {this.props.unit}
        </td>
        <td className="data_row_pin">
          <Pin
            toggle_name={this.props.id}
            pinAction={(toggle_name) => this.props.pinAction(toggle_name)}
            init_state={this.props.is_pinned}
            no_corner={true}
          />
        </td>
      </tr>
    );
  }
}

/* Box view */
class Category extends React.PureComponent {
  render() {
    return (
      <section className="category">
        <span className="section_title">{this.props.name}</span>
        <div className="data_boxes_container"> {this.props.children}</div>
      </section>
    );
  }
}

class DataBox extends React.PureComponent {
  render() {
    return (
      <div className="data_box">
        <Pin
          toggle_name={this.props.id}
          pinAction={(toggle_name) => this.props.pinAction(toggle_name)}
          init_state={this.props.is_pinned}
        />
        <span className="data_box_name">{this.props.name}</span>
        <span className="data_box_value">
          {this.props.val} {this.props.unit}
        </span>
      </div>
    );
  }
}

export { DataTable, DataRow, Category, DataBox };
