import React from "react";

function EllipsisLoader() {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

class LoaderAnimation extends React.PureComponent {
  render() {
    return (
      this.props.show && (
        <div className="loader_animation">
          <EllipsisLoader />
        </div>
      )
    );
  }
}

class OldDataNotice extends React.PureComponent {
  render() {
    return (
      this.props.show && (
        <div className="car_offline_from_start">
          <span>Car is offline</span>
          <button
            className="btn show_old_data_btn"
            onClick={this.props.showOldData}
          >
            Show old data
          </button>
        </div>
      )
    );
  }
}

export { LoaderAnimation, OldDataNotice };
